import React, { useState } from 'react';
import styled from 'styled-components';
import { useResetRecoilState } from 'recoil';

import {
  myPlanComingYnAtom,
  myPlanCreatedYearAtom,
  myPlanCurrentPageAtom,
  myPlanSearchTypeAtom,
} from 'src/atoms/myPlan';

import media from 'src/styles/media';

import { ReactComponent as FindIcon } from '../../../assets/svgs/findIcon.svg';
import { ReactComponent as RotateIcon } from '../../../assets/svgs/rotate.svg';

interface Props {
  placeHolder: string;
  setKeyword?: React.Dispatch<React.SetStateAction<string>>;
}

const EnterSearch = ({ placeHolder, setKeyword }: Props) => {
  const [value, setValue] = useState('');

  const resetSearchType = useResetRecoilState(myPlanSearchTypeAtom);
  const resetComingYn = useResetRecoilState(myPlanComingYnAtom);
  const resetCreatedYear = useResetRecoilState(myPlanCreatedYearAtom);
  const resetCurrentPage = useResetRecoilState(myPlanCurrentPageAtom);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (setKeyword) setKeyword(value);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      if (value.trim() === '') {
        e.preventDefault();
        alert('검색어를 입력해주세요');
        setValue('');
        if (setKeyword) setKeyword('');
      }
    }
  };

  const handleRotate = () => {
    if (setKeyword) setKeyword('');
    setValue('');
    resetComingYn();
    resetCreatedYear();
    resetCurrentPage();
    resetSearchType();
  };

  const handleSearch = () => {
    if (value.trim() === '') {
      alert('검색어를 입력해주세요');
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Button onClick={handleSearch}>
        <FindIcon />
      </Button>
      <Input
        placeholder={placeHolder}
        value={value}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
      />
      <Button onClick={handleRotate}>
        <RotateIcon />
      </Button>
    </Form>
  );
};

export default EnterSearch;

const Form = styled.form`
  display: flex;
  justify-content: center;
  position: relative;

  margin: 0px 20px;
  height: 45px;

  ${media.mobile`
    height: 55px;
  `}
`;

const Input = styled.input`
  width: 480px;

  padding: 15px;
  padding-left: 50px;

  border: none;
  border-radius: 35px;
  outline: none;

  box-shadow: ${(props) => `0px 3px 5px 0px ${props.theme.colors.lightGrey}`};
  font-size: 14px;

  &::placeholder {
    color: ${(props) => props.theme.colors.darkGrey};
    font-family: 'Pretendard-Regular';

    ${media.mobile`
      padding-left: 5px;
      font-size: 23px;
    `}
  }

  ${media.mobile`
    width: 500px;
    font-size: 23px;
  `}
`;

const Button = styled.button`
  position: absolute;
  top: 9px;
  padding: 5px;

  border: none;
  outline: none;

  background-color: inherit;
  cursor: pointer;

  svg {
    width: 20px;
    height: 15px;
    fill: ${(props) => props.theme.colors.darkGrey};

    ${media.mobile`
      width: 27px;
      height: 22px;
    `}
  }

  &:first-child {
    left: 10px;

    ${media.mobile`
      left: 15px;
    `}
  }

  &:last-child {
    right: 10px;

    ${media.mobile`
      right: 15px;
    `}
  }
`;
