import { styled } from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { useEffect } from 'react';
import media from 'src/styles/media';

import { usePlaceInfo } from 'src/hooks/api/usePlaceInfo';
import { PlaceInfo } from 'src/@types/api/placeInfo';
import { ReactComponent as Close } from 'src/assets/svgs/close.svg';
import placeIdAtom from 'src/atoms/placeIdAtom';
import overviewTitleAtom from 'src/atoms/overviewTitleAtom';
import { editReviewAtom } from 'src/atoms/review';

import ModalOverlay from '../common/modal/ModalOverlay';
import ImageBox from '../common/modal/ImageBox';
import Overview from '../common/modal/Overview';
import Address from './Address';
import PlaceReview from '../PlaceReview';

interface Props {
  onClose: () => void;
}

interface Info {
  data: {
    data: PlaceInfo;
  };
  isLoading: boolean;
  isError: boolean;
}

const PlaceModal = ({ onClose }: Props) => {
  const setTitle = useSetRecoilState(overviewTitleAtom);
  let { id: currentId, name: content } = useRecoilValue(placeIdAtom);
  const myReview = localStorage.getItem('myReview');
  const navigate = useNavigate();
  const [isEditReview, setIsEditReview] = useRecoilState(editReviewAtom);

  if (myReview) {
    const review = JSON.parse(myReview);
    currentId = review.placeId;
    content = '';
  }

  const { data: placeInfoApi, isLoading, isError }: Info = usePlaceInfo(currentId);

  if (isError) {
    onClose();
  }

  const onRefresh = () => {
    if (myReview && isEditReview) {
      navigate(0);
      setIsEditReview(false);
    }
  };

  useEffect(() => {
    setTitle(true);
  }, []);

  return (
    <ModalOverlay>
      {!isLoading && (
        <StyledModalLayout>
          <StyledIcon onClick={onRefresh}>
            <Close onClick={onClose} />
          </StyledIcon>
          <StyledName>
            {placeInfoApi.data.name}
            <StyledContent>{content}</StyledContent>
          </StyledName>
          <StyledContentType>{placeInfoApi.data.contentTypeName}</StyledContentType>
          <StyledImageBoxInner>
            <ImageBox image={placeInfoApi.data.imageOrigin} />
          </StyledImageBoxInner>
          <Address address={placeInfoApi.data.address} />
          <Overview overview={placeInfoApi.data.overview} />
          <PlaceReview currentId={currentId} />
        </StyledModalLayout>
      )}
    </ModalOverlay>
  );
};

export default PlaceModal;

const StyledModalLayout = styled.div`
  display: flex;
  flex-flow: column;
  align-content: center;
  justify-content: center;
  flex-wrap: wrap;

  width: 1064px;
  height: 684px;
  border: none;
  border-radius: 20px;
  background-color: ${(props) => props.theme.colors.whiteFont};

  ${media.mobile`
    display: flex;
    align-content: flex-start;
    flex-direction: row;

    width: 700px;
    height: 1024px;
    padding: 20px 0px 20px 0px;
    margin-top: 30px;
    
    overflow: auto;
  `}
`;

const StyledName = styled.div`
  width: 430px;
  margin-bottom: 15px;

  font-family: 'Pretendard-Bold';
  color: ${(props) => props.theme.colors.blackFont};
  font-size: 25px;
  text-align: left;

  ${media.mobile`
    text-align: center;
    position: relative;
    width: 100%;
  `}
`;

const StyledContent = styled.span`
  margin-left: 10px;

  font-family: 'Pretendard-Thin';
  color: ${(props) => props.theme.colors.blackFont};
  font-size: 17px;

  ${media.mobile`
    display:none;
  `}
`;

const StyledContentType = styled.div`
  text-align: center;

  width: 60px;
  padding: 10px;
  margin-bottom: 20px;

  border-radius: 25px;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
  color: #3a3939;
  font-size: 12px;
  font-family: 'Pretendard-Regular';
`;

const StyledImageBoxInner = styled.div`
  margin-bottom: 30px;

  ${media.mobile`
    text-align: center;
    width: 100%;
  `}
`;

const StyledIcon = styled.div`
  position: relative;
  bottom: 17px;
  left: 950px;

  width: 21px;
  height: 21px;

  cursor: pointer;

  ${media.mobile`
    left: 310px;
    bottom: -5px;
  `}
`;
