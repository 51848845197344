export const BASE_URL = 'https://api.triportreat.site';

export const END_POINTS = {
  CONTENT_TYPE: '/places/content-type',
  PLACES: '/places',

  REGIONS: '/regions',
  REVIEWS: '/reviews',

  PLANS: '/plans',
  PLAN: '/plans',
  SHARE: '/plans/share',

  INFO: '/user/info',
  NICKNAME: '/user/nickname',
};
