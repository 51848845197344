import theme from 'src/styles/theme';
import styled from 'styled-components';
import { useRecoilValue } from 'recoil';
import { useEffect, useState } from 'react';
import { useMutation } from '@tanstack/react-query';

import curDayAtom from 'src/atoms/curDayAtom';
import { planTitleAtom } from 'src/atoms/plan';
import { totalPlanDetailAtom } from 'src/atoms/planDetail';

import { PlaceListTypes } from 'src/@types/api/placeList';
import { SchedulesType } from 'src/@types/api/schedules';
import { SchedulesTypeWithRegions } from 'src/@types/api/planDetail';

import ModalOverlay from 'src/components/common/modal/ModalOverlay';
import PlanTitle from 'src/components/plan/PlanNavBar/PlanTitle';
import RegionCategoryList from 'src/components/RegionCategoryList';
import EnterSearch from 'src/components/EnterSearch';
import ContentTypeFilterItemList from 'src/components/ContentTypeFilterItemList';
import PlaceList from 'src/components/PlaceList';
import DayCategory from 'src/components/DayCategory';
import SelectedPlaceCardList from 'src/components/SelectedPlaceCardList';
import KaKaoMap from 'src/components/KaKaoMap';
import CloseButton from 'src/components/common/modal/CloseButton';

import { usePatchTotalPlan } from 'src/hooks/api/usePatchTotalPlan';
import { useDisableButton } from 'src/hooks/useDisableButton';
import { StyledButton } from 'src/components/common/modal/LinkButton/LinkButton';
import media from 'src/styles/media';

interface Props {
  detailData: SchedulesTypeWithRegions;
  onClose: () => void;
}

const filterRegions = (regions: { regionIds: number[]; regionNames: string[] }) => {
  const { regionIds, regionNames } = regions;
  const result = regionIds.map((id, index) => ({
    id,
    name: regionNames[index],
  }));

  return result;
};

const PlanChangeModal = ({ onClose, detailData }: Props) => {
  const [keyword, setKeyword] = useState('');
  const [curRegionId, setCurRegionId] = useState(0);
  const [selectedPlaceList, setSelectedPlaceList] = useState<PlaceListTypes[]>([]);

  const title = useRecoilValue(planTitleAtom);
  const curDay = useRecoilValue(curDayAtom);
  const totalPlanDetail = useRecoilValue(totalPlanDetailAtom);

  const regions = filterRegions(detailData?.regions);
  const isClickPossible = useDisableButton();

  useEffect(() => {
    setSelectedPlaceList(totalPlanDetail[curDay - 1]?.items);
  }, [curDay, totalPlanDetail]);

  useEffect(() => {
    if (curRegionId === 0) setCurRegionId(detailData?.regions.regionIds[0]);
  }, [detailData]);

  const { mutate } = useMutation({
    mutationFn: (payload: SchedulesType) => usePatchTotalPlan(detailData.planId, payload),
    onSuccess: () => {
      alert('성공적으로 저장이 완료되었습니다!');
      window.location.href = '/myPage/myPlan';
    },
    onError: () => {
      alert('저장이 실패했습니다!');
      onClose();
    },
  });

  const handlePlanChange = () => {
    const schedules = totalPlanDetail.map((data) => {
      return {
        scheduleId: data.scheduleId,
        date: data.date,
        schedulePlaces: data.items.map((schedulePlace, idx) => ({
          schedulePlaceId: schedulePlace.schedulePlaceId ? schedulePlace.schedulePlaceId : null,
          placeId: schedulePlace.placeId,
          memo: schedulePlace.memo ?? '',
          visitOrder: idx + 1,
          expense: schedulePlace.expense ?? 0,
        })),
      };
    });

    const result = {
      planId: detailData.planId,
      title,
      startDate: detailData.startDate,
      endDate: detailData.endDate,
      code: detailData.code,
      schedules,
    };

    mutate(result);
  };

  localStorage.setItem('prevPage', '/place');

  return (
    <ModalOverlay>
      <Wrapper>
        <TitleBox>
          <PlanTitle type="MODAL" />
        </TitleBox>

        <Content>
          <PlaceBox>
            <SearchLayer>
              <section>
                {detailData && (
                  <RegionCategoryList
                    data={regions}
                    type="BUTTON"
                    curRegionId={curRegionId}
                    setCurRegionId={setCurRegionId}
                  />
                )}
                <EnterSearch placeHolder="장소를 검색해보세요!" setKeyword={setKeyword} />
                <ContentTypeFilterItemList />
              </section>

              {detailData && (
                <PlaceList keyword={keyword} setKeyword={setKeyword} curRegionId={curRegionId} />
              )}
            </SearchLayer>

            <DayLayer>
              <DayCategory />
              <SelectedPlaceCardList />
            </DayLayer>
          </PlaceBox>
          <MapLayer>
            <KaKaoMap list={selectedPlaceList} curDay={curDay} />
          </MapLayer>
        </Content>

        <ButtonBox>
          <SaveButtonBox $isClicked={isClickPossible}>
            <SaveButton onClick={handlePlanChange} $isClicked={isClickPossible}>
              저장
            </SaveButton>
          </SaveButtonBox>
          <CloseButton onClick={onClose} color={theme.colors.darkGrey}>
            닫기
          </CloseButton>
        </ButtonBox>
      </Wrapper>
    </ModalOverlay>
  );
};

export default PlanChangeModal;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  width: 80vw;
  height: 85vh;
  border-radius: 35px;

  background-color: white;
  overflow: hidden;
`;

const TitleBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 8%;
`;

const Content = styled.div`
  display: flex;

  width: 80vw;
  height: 83vh;

  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  ${media.mobile`
    flex-direction: column;
  `}
`;

const PlaceBox = styled.div`
  display: flex;
  width: 50%;

  ${media.mobile`
    width: 100%;
    height:50rem;
    margin-bottom:20px;
  `}
`;

const SearchLayer = styled.div`
  width: 50%;
  border-left: ${(props) => `1px solid ${props.theme.colors.lightGrey}`};
  border-right: ${(props) => `1px solid ${props.theme.colors.lightGrey}`};

  box-sizing: border-box;

  & > section {
    padding: 20px;
  }

  ${media.mobile`
  width: 50%;  
`}
`;

const DayLayer = styled.div`
  width: 50%;
  height: inherit;
  padding: 20px;

  box-sizing: border-box;

  ${media.mobile`
  width: 50%;  
`}
`;

const MapLayer = styled.div`
  width: 48%;

  ${media.mobile`
  width:95%;
  height:100%;
  margin: 20px;;
`}
`;

const ButtonBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0px;

  width: 100%;
  height: 7vh;
  background-color: white;
  z-index: 99;

  button {
    width: 75px;
    height: 45px;
    margin-top: 15px;
  }
`;

const SaveButtonBox = styled.div<{ $isClicked: boolean }>`
  cursor: ${(props) => (props.$isClicked ? 'pointer' : 'not-allowed')};
`;

const SaveButton = styled.button<{ $isClicked: boolean }>`
  ${StyledButton}
  background-color: ${(props) =>
    props.$isClicked ? props.theme.colors.mainColor : props.theme.colors.darkGrey};
  pointer-events: ${(props) => (props.$isClicked ? props.theme.colors.mainColor : 'none')};
  color: white;
`;
